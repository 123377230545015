import { sortBy } from "lodash";

import { Addon, ProductIngredientGroup, IngredientLimiter, ProductIngredientGroups } from "Types";
import { isNegative } from "Utils";

const canHaveManyOfAddon = ({ ingredient, limit }: IngredientLimiter) => !(limit === 1 || isNegative(ingredient.price));

const addonsToShow = (
    ingredients: IngredientLimiter[],
    isOverLimit: boolean,
    open: boolean,
    maxAddonsToShow: number
) => {
    if (isOverLimit) {
        return ingredients.slice(0, open ? ingredients.length : maxAddonsToShow);
    }
    return ingredients;
};

const getSelectedAddon = (addon: Addon, selectedAddons: Addon[]) => {
    return selectedAddons.find(
        selectedAddon => selectedAddon.groupId === addon.groupId && selectedAddon.name === addon.name
    );
};

const getActiveAddonsIndexes = (selectedAddons: any[], productAddonGroup: ProductIngredientGroup) => {
    return selectedAddons.reduce<number[]>((acc, addon) => {
        const index = productAddonGroup.ingredients.findIndex(ingredientLimiter => {
            const { ingredient } = ingredientLimiter;
            return (
                ingredient.groupId === addon.groupId &&
                ingredient.name === addon.name &&
                canHaveManyOfAddon(ingredientLimiter)
            );
        });

        if (index !== -1 && !acc.includes(index)) {
            acc.push(index);
        }

        return acc;
    }, []);
};

const grouped = (productAddonGroups: ProductIngredientGroups, name: string, extraAddonsForText: string) => {
    const sorted = sortBy(productAddonGroups, ["displayByName", "sortOrder"]);

    return sorted.reduce<ProductIngredientGroup[]>((groups, nextGroup) => {
        let genericGroup = groups.find(group => !group.displayByName);

        if (genericGroup && !nextGroup.displayByName) {
            const addedIngredients = genericGroup.ingredients.concat(
                nextGroup.ingredients.sort((a, b) => a.sortOrder - b.sortOrder)
            );
            genericGroup.ingredients = addedIngredients;
        } else if (!nextGroup.displayByName) {
            groups.push({
                displayByName: false,
                ingredients: nextGroup.ingredients,
                name: `${extraAddonsForText} ${name}`,
                sortOrder: nextGroup.sortOrder,
                limit: nextGroup.limit
            });
        } else {
            groups.push({
                ...nextGroup,
                ingredients: nextGroup.ingredients.sort((a, b) => a.sortOrder - b.sortOrder)
            });
        }

        return groups;
    }, []);
};

export { addonsToShow, getActiveAddonsIndexes, grouped, getSelectedAddon, canHaveManyOfAddon };
