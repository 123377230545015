import React from "react";

import { Flex, Icon, Link } from "Atoms";
import { Modal, ModalHeader, ModalBody, ModalCloseBtn } from "Molecules";
import { useTheme } from "Providers";
import { modalNames } from "Constants";
import { OnlineOpeningHours } from "OnlineComponents";
import { LanguageHeader } from "Components";

type Props = {
    modalContent: any;
    closeModal: (modalToClose: string) => void;
};

export const OnlineInfoModal: React.FC<Props> = ({ modalContent, closeModal }) => {
    const { colors } = useTheme();
    const searchInfo = `${encodeURIComponent(modalContent.restaurantName)},+${encodeURIComponent(
        modalContent.addressInfo
    )}`;
    const googleUrl = encodeURI(`https://www.google.com/maps/search/?api=1&query=${searchInfo}`);
    return (
        <Modal open={true} size="xs" isScrolling>
            <ModalHeader>
                <Flex width="100%" justifyContent="space-between" alignItems="center">
                    <LanguageHeader tid="placeAndOpeningHours" as="h3" size="md" m="0" />
                    <ModalCloseBtn onClick={() => closeModal(modalNames.ONLINE_INFO_MODAL)} />
                </Flex>
            </ModalHeader>
            <ModalBody>
                <Flex flexDirection="column">
                    <Flex margin="0 0 1em 0" flexDirection="column">
                        <Flex alignItems="flex-start">
                            <Icon
                                name="FaMapMarker"
                                color={colors.icon}
                                width="1.5rem"
                                height="1.5rem"
                                style={{ marginRight: "0.75rem" }}
                            />
                            <Link
                                isExternal
                                color="#767676"
                                href={`https://www.google.com/maps/search/?api=1&query="${googleUrl}"`}
                                className="header-grid-text-adress"
                            >
                                {modalContent.addressInfo}
                            </Link>
                        </Flex>
                        <iframe
                            src={`https://maps.google.com/maps?q=${searchInfo}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
                            width="100%"
                            height="250px"
                            frameBorder={0}
                            scrolling="1000"
                            style={{ marginTop: "1rem" }}
                        />
                    </Flex>
                    <Flex alignItems="flex-start" marginTop={4}>
                        <Icon
                            name="FaClock"
                            color={colors.icon}
                            width="1.5rem"
                            height="1.5rem"
                            style={{ marginRight: "0.75rem", marginTop: "1rem" }}
                        />
                        <OnlineOpeningHours activeHours={modalContent.activeHours} />
                    </Flex>
                </Flex>
            </ModalBody>
        </Modal>
    );
};
