import React, { useState } from "react";

import { Icon, Header, Text } from "Atoms";
import { Accordion, AccordionItem, AccordionTitle, AccordionCloseIcon, AccordionContent } from "Organisms";
import { Allergen as AllergenType } from "Types";
import { LanguageHeader } from "Components";
import { useLanguage } from "Providers";

type Props = {
    allergens?: AllergenType[];
    contents?: string;
};

enum AccordionIndex {
    CONTENTS = 0,
    ALLERGENS = 1
}

export const AllergenAndContentInfo: React.FC<Props> = ({ allergens, contents }) => {
    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
    const { translate } = useLanguage();

    const noContents = !allergens && !contents;

    if (noContents) return null;

    const hasAllergens = !!allergens?.length;
    const hasContents = !!contents;

    const onSetActiveIndex = (index: AccordionIndex) => {
        setActiveIndexes(prev => (prev.includes(index) ? [] : [index]));
    };

    return (
        <Accordion defaultIndex={[]} activeIndexes={activeIndexes}>
            {hasContents && (
                <AccordionItem
                    key={AccordionIndex.CONTENTS}
                    isActive={activeIndexes.includes(AccordionIndex.CONTENTS)}
                    onClick={() => onSetActiveIndex(AccordionIndex.CONTENTS)}
                >
                    <AccordionTitle>
                        <LanguageHeader tid="productContents" as="h4" size="md" margin="0" fontWeight="normal" />
                        <AccordionCloseIcon />
                    </AccordionTitle>
                    <AccordionContent>
                        <Header as="h5" size="sm" fontWeight="normal">
                            {contents}
                        </Header>
                    </AccordionContent>
                </AccordionItem>
            )}
            {hasAllergens && (
                <AccordionItem
                    key={AccordionIndex.ALLERGENS}
                    isActive={activeIndexes.includes(hasContents ? AccordionIndex.ALLERGENS : 0)}
                    onClick={() => onSetActiveIndex(hasContents ? AccordionIndex.ALLERGENS : 0)}
                >
                    <AccordionTitle>
                        <LanguageHeader tid="allergens" as="h4" size="md" margin="0" fontWeight="normal" />
                        <AccordionCloseIcon />
                    </AccordionTitle>
                    <AccordionContent>
                        <Header as="h5" size="sm" fontWeight="normal">
                            {translate("allergensWith")} <Icon name="FaChevronDown" width="14px" height="14px" />{" "}
                            {translate("allergensCanExpandWith")}.
                        </Header>
                        <Allergens allergens={allergens} />
                    </AccordionContent>
                </AccordionItem>
            )}
        </Accordion>
    );
};

const Allergens: React.FC<{ allergens: AllergenType[] }> = ({ allergens }) => {
    const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
    const onSetActiveIndex = (index: number) => {
        setActiveIndexes(prev => (prev.includes(index) ? [] : [index]));
    };
    return (
        <Accordion defaultIndex={[]} activeIndexes={activeIndexes}>
            {allergens.map((allergen, idx) => (
                <AccordionItem
                    key={allergen.id}
                    width="100%"
                    isActive={activeIndexes.includes(idx)}
                    onClick={(event: React.MouseEvent<any, MouseEvent>) => {
                        event.stopPropagation();
                        onSetActiveIndex(idx);
                    }}
                >
                    <AccordionTitle>
                        <Header as="h5" size="sm" margin="0">
                            {allergen.name}
                        </Header>
                        {allergen.desc && <AccordionCloseIcon />}
                    </AccordionTitle>
                    {allergen.desc && (
                        <AccordionContent>
                            <Text>{allergen.desc}</Text>
                        </AccordionContent>
                    )}
                </AccordionItem>
            ))}
        </Accordion>
    );
};
