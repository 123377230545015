import React from "react";

import {
    Divider,
    Image,
    Link,
    Icon,
    Flex,
    Header,
    Text,
    Box,
    Popup,
    PopupTrigger,
    PopupContent,
    PopupBody,
    PseudoBox
} from "Atoms";
import { TableMode } from "Types";
import { LanguageText, LanguageHeader } from "Components";
import { OnlineOpeningHours, OnlineOpeningHour } from "OnlineComponents";

type Props = {
    restaurant: any;
    addressInfo: any;
    table: TableMode | null;
};

export const OnlineInfoCard: React.FC<Props> = ({ restaurant, addressInfo, table }) => {
    const {
        activeHours,
        name,
        imageUrl,
        settings: {
            onlineSettings: { closeOnlines }
        },
        contactInformation
    } = restaurant;

    const searchInfo = `${encodeURIComponent(name)},+${encodeURIComponent(addressInfo)}`;
    return (
        <Box display="-webkit-box" bg="white" padding={2} rounded="md">
            {!!imageUrl && (
                <Image
                    fallbackSrc={imageUrl}
                    src={imageUrl}
                    display="flex"
                    width="128px"
                    rounded="md"
                    objectFit="contain"
                />
            )}
            <Flex direction="column" align="flex-start" justify="center" height="auto" paddingLeft={2}>
                <Header as="h1">{name}</Header>
                {!!contactInformation.email && contactInformation.email !== "" && (
                    <Text as="span">
                        <Icon name="FaEnvelope" color="#666" size="tiny" />
                        <Link href={`mailto:${contactInformation.email}?subject=Hello`} target="_blank">
                            <LanguageText tid="contactRestaurant" as="span" />
                        </Link>
                    </Text>
                )}
                <Text as="span">
                    <Icon name="FaMapMarker" color="#666" size="tiny" />
                    <Link
                        isExternal
                        href={`https://www.google.com/maps/search/?api=1&query=${searchInfo}`}
                        className="header-grid-text-adress"
                    >
                        {addressInfo}
                    </Link>
                </Text>

                {!!activeHours?.length && (
                    <Flex height="auto">
                        <Icon name="FaClock" color="#666" size="tiny" />
                        <Popup placement="bottom">
                            <PopupTrigger>
                                <PseudoBox _hover={{ cursor: "pointer" }} display="flex">
                                    <OnlineOpeningHour activeHours={activeHours} />
                                    <LanguageText tid="more" color="blue.500" />
                                </PseudoBox>
                            </PopupTrigger>
                            <PopupContent zIndex={9999} minW={40}>
                                <PopupBody>
                                    <Flex>
                                        <Icon name="FaClock" size="tiny" color="#666" />
                                        <LanguageText tid="openingHours" />
                                    </Flex>
                                    <Divider />
                                    <OnlineOpeningHours activeHours={activeHours} />
                                </PopupBody>
                            </PopupContent>
                        </Popup>
                    </Flex>
                )}
            </Flex>

            {table && table.name && (
                <Box borderLeft={"1px solid black"} pl={8} ml={8} mr={8}>
                    <LanguageHeader tid="youreingTo" as="h1" color="gray.700" mb={1} />
                    <Header size="2xl" color="gray.800" textAlign="center" mt={2}>
                        {table.name}
                    </Header>
                </Box>
            )}
        </Box>
    );
};
