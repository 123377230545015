import React, { useEffect } from "react";

import { BundleProductCategory, CartProduct, PosProviderRefProductHashMap } from "Types";
import { Box } from "Atoms";
import { usePos } from "Providers";
import { buildBundleProductCats, getPreselectedBundleProductItems } from "../utils";
import { CustomerBundleCategory } from "./";
import { getSingleBundleProductCategories } from "Utils";
import { ExtendedBundleProductCategory } from "OnlineTypes";

type Props = {
    bundleProductCategories: BundleProductCategory[];
    refProducts: PosProviderRefProductHashMap;
    bundleCartProduct: CartProduct;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
};

export const CustomerBundleCategories: React.FC<Props> = ({
    bundleProductCategories,
    refProducts,
    bundleCartProduct,
    setBundleCartProduct
}) => {
    const { addManyBundleItemsToBundleProduct, hideFromStockIds } = usePos();

    const categories = [...buildBundleProductCats(bundleProductCategories, refProducts)].sort(
        (a, b) => a.sortOrder - b.sortOrder
    );

    useEffect(() => {
        if (!bundleCartProduct.isFinished && bundleCartProduct.orderProduct.selectedBundleProductItems) {
            const {
                orderProduct: { selectedBundleProductItems }
            } = bundleCartProduct;

            const singleProductCategories = getSingleBundleProductCategories(categories);

            // Check so we don't override other categories when user wants to edit bundle product
            const hasAlreadyPreselectedSingleProductCategories = singleProductCategories.every(category => {
                return bundleCartProduct.orderProduct.selectedBundleProductItems?.find(selectedBundleProductItem => {
                    const hasMatchingId = selectedBundleProductItem.bundleProductCategoryId === category.id;
                    const hasSelectedBundleItem = !!selectedBundleProductItem.refProductId;
                    return hasMatchingId && hasSelectedBundleItem;
                });
            });

            if (!hasAlreadyPreselectedSingleProductCategories) {
                const preSelectedBundleItems = getPreselectedBundleProductItems(
                    selectedBundleProductItems,
                    singleProductCategories as ExtendedBundleProductCategory[]
                );

                addManyBundleItemsToBundleProduct(preSelectedBundleItems);
            }
        }
    }, [bundleCartProduct.isFinished]);
    return (
        <Box my={6}>
            {categories.map((bundleProductCategory, index) => {
                const categoryBundleItems = bundleCartProduct.orderProduct.selectedBundleProductItems?.filter(
                    bundleItem => bundleProductCategory.id == bundleItem.bundleProductCategoryId
                );

                const filteredBundleProductCategory = {
                    ...bundleProductCategory,
                    refProductIdList: bundleProductCategory.refProductIdList.filter(id => !hideFromStockIds?.has(id))
                };

                return (
                    <CustomerBundleCategory
                        idx={index.toString()}
                        key={index}
                        bundleProductCategory={filteredBundleProductCategory}
                        bundleCartProduct={bundleCartProduct}
                        setBundleCartProduct={setBundleCartProduct}
                        // Only used for memo optimization
                        categoryBundleItems={categoryBundleItems}
                    />
                );
            })}
        </Box>
    );
};
