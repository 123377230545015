import React from "react";

import { Image, ImageProps } from "Atoms";
import { useOnlineStore } from "Stores";

type Device = "mobile" | "desktop";

type Props = {
    device: Device;
} & Partial<ImageProps>;

export const OnlinePromotionHeader: React.FC<Props> = ({ device, ...rest }) => {
    const { partnerPromotion } = useOnlineStore();
    const hasPartnerPromotion = !!partnerPromotion?.id;
    const isMobile = device === "mobile";

    if (!hasPartnerPromotion || !partnerPromotion?.headerImageUrl) {
        return null;
    }

    return (
        <Image
            src={partnerPromotion?.headerImageUrl}
            position="absolute"
            zIndex={10}
            bottom="0"
            maxHeight={isMobile ? "7rem" : "15rem"}
            maxWidth={isMobile ? "7rem" : "15rem"}
            right="0"
            boxShadow="image"
            {...rest}
        />
    );
};
