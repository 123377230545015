import React from "react";

import { BundleProductCategory, CartProduct } from "Types";
import { BundleItemOperation } from "Providers";
import { useOrderWindowsStore } from "Stores";
import { Radio, Checkbox, Image, Center } from "Atoms";

type Props = {
    bundleCartProduct: CartProduct;
    isMultiBundleCat: boolean;
    isSelected: boolean;
    bundleItem: any;
    catLimitReached: boolean;
    bundleProductCategory: BundleProductCategory;
    onAccordionClick: any;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
};

export const BundleItemIcon: React.FC<Props> = ({
    bundleCartProduct,
    isMultiBundleCat,
    isSelected,
    bundleItem,
    catLimitReached,
    bundleProductCategory,
    setBundleCartProduct,
    onAccordionClick
}) => {
    const { CartManager } = useOrderWindowsStore();
    const { updateBundleCartProductWithSelectedBundleItems } = CartManager;
    const hasImage = !!bundleItem.imageUrl;

    const props = {
        isChecked: isSelected,
        size: "xxl",
        variantColor: catLimitReached && isSelected ? "green" : "blue",
        onChange: () => {
            if (!isSelected && !bundleItem.outOfStock) {
                onAccordionClick();
            } else if (isSelected && isMultiBundleCat) {
                const updatedBundleCartProduct = updateBundleCartProductWithSelectedBundleItems(
                    bundleCartProduct,
                    bundleItem,
                    bundleProductCategory,
                    null,
                    false,
                    null,
                    BundleItemOperation.RESET_BUNDLE_ITEM
                );

                setBundleCartProduct(updatedBundleCartProduct);
            }
        }
    } as const;

    if (hasImage) {
        const backgroundColor = catLimitReached && isSelected ? "green.500" : isSelected ? "blue.500" : "gray.200";
        return (
            <Center
                h="auto"
                p={1}
                mr={3}
                onClick={props.onChange}
                bg={backgroundColor}
                rounded="md"
                transition="all 0.3s"
            >
                <Image size="3.5rem" objectFit="cover" rounded="md" src={bundleItem.imageUrl} />
            </Center>
        );
    } else {
        const Component = isMultiBundleCat ? Checkbox : Radio;
        return (
            <Center size="47px" h="auto" mr={3}>
                <Component {...props} iconSize="20px" />
            </Center>
        );
    }
};
