import React from "react";
import { Helmet } from "react-helmet-async";

const NoIndexHelmet: React.FC = () => {
    return (
        <>
            {/** @ts-ignore */}
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
        </>
    );
};

export default NoIndexHelmet;
