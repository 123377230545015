import React from "react";
import { IoIosAdd } from "@react-icons/all-files/io/IoIosAdd";
import { IoIosRemove } from "@react-icons/all-files/io/IoIosRemove";

import { useLanguage, useOnline } from "Providers";
import { DISCOUNT_TYPE, Discount, OnlineProduct } from "Types";
import { Box, NewGrid, Stack, PseudoBox, StackProps, Text } from "Atoms";
import { isCartProductIncrementDisabled } from "Utils";
import { formatSwedishFinancialNumbers } from "PriceUtils";
import { OnlineText, OnlineIconButton } from "./";
import { BundleProductItems, ModificationsList, OnlineAddonsList } from "Organisms";
import { LoadingMessage } from "TempUtils";
import { useOnlineStore, useOrderWindowsStore } from "Stores";

type Props = {
    onCheckout?: boolean;
    isProductCommentsEnabled?: boolean;
    appliedDiscount?: Discount;
} & StackProps;

const isDecrementDisabled = (orderProductQuantity: number, cartProductsLength: number, onCheckout: boolean) => {
    // If we are on the OnlineOrder page, we want to allow remove via decrement
    return onCheckout && cartProductsLength <= 1 && orderProductQuantity <= 1;
};

export const OnlineCustomerCartList: React.FC<Props> = ({
    onCheckout = false,
    isProductCommentsEnabled,
    appliedDiscount,
    ...rest
}) => {
    const { productsStock, removeCartProduct, incrementOrderProduct, decrementOrderProduct, getActiveOrderWindow } =
        useOrderWindowsStore();
    const { setRestaurantProductModalState: toggleProductModal } = useOnlineStore();
    const { isOnlineExpress } = useOnline();
    const { translate } = useLanguage();

    const orderWindow = getActiveOrderWindow();

    if (!orderWindow || !orderWindow?.cartProducts) {
        return <LoadingMessage />;
    }

    const cartProducts = orderWindow?.cartProducts;

    return (
        <Stack stretch={6} w="100%" overflow="auto" h="auto" {...rest}>
            {cartProducts.map(cartProduct => {
                const { menuProduct, menuBundleProduct, orderProduct } = cartProduct;

                const isMenuBundleProduct = !!menuBundleProduct;
                const hasMenuProductModifications = !!menuProduct?.refProduct?.modifications;
                const isProductItemClickable = !onCheckout && (isMenuBundleProduct || hasMenuProductModifications);

                const isBundleFinished = cartProduct?.isFinished ?? true;
                const disabledDecrement = isDecrementDisabled(orderProduct.quantity, cartProducts.length, onCheckout);
                const totalProductPrice = orderProduct.totalPrice + (orderProduct?.discountValue ?? 0);
                const hasMenuItemDiscounts: boolean = !!orderProduct?.discountValue;
                const hasCombinedDiscounts: boolean = orderProduct?.discountIds
                    ? orderProduct.discountIds.length > 1
                    : false;
                const isIncrementBtnDisabled = isCartProductIncrementDisabled(cartProduct, productsStock);

                const onCartListItemClick = () => {
                    if (!isProductItemClickable) return;
                    // @todo: Break this out for onClick in RestaurantProduct

                    if (menuBundleProduct) {
                        return toggleProductModal({
                            onlineProduct: menuBundleProduct as OnlineProduct,
                            cartProduct,
                            isProductCommentsEnabled: isProductCommentsEnabled || false
                        });
                    }

                    if (menuProduct) {
                        toggleProductModal({
                            onlineProduct: menuProduct as OnlineProduct,
                            cartProduct,
                            isProductCommentsEnabled: isProductCommentsEnabled || false
                        });
                    }
                };

                const getDiscountInfo = (): {
                    name: string;
                    variant: "subscription" | "discount";
                } | null => {
                    if (cartProduct.fixedDiscount?.isFixedDiscountActive) {
                        const fixedDiscount = orderWindow?.fixedDiscounts?.find(
                            discount => discount.id === cartProduct.fixedDiscount?.fixedDiscountId
                        );

                        if (fixedDiscount) {
                            return {
                                name: fixedDiscount.name,
                                variant:
                                    fixedDiscount.type === DISCOUNT_TYPE.SUBSCRIPTION_DISCOUNT
                                        ? "subscription"
                                        : "discount"
                            };
                        }
                    } else if (appliedDiscount?.name) {
                        return {
                            name: appliedDiscount.name,
                            variant: "discount"
                        };
                    }
                    return null;
                };

                const combinedDiscountNames = () => {
                    const fixedDiscount = orderWindow?.fixedDiscounts?.find(
                        discount => discount.id === cartProduct.fixedDiscount?.fixedDiscountId
                    );

                    if (fixedDiscount && appliedDiscount?.name) {
                        return [fixedDiscount.name, appliedDiscount?.name].map((name: string, index: number) => {
                            return (
                                <OnlineText margin="0" marginLeft="0.25rem" key={index}>{`- ${translate(
                                    "discount"
                                )} (${name})`}</OnlineText>
                            );
                        });
                    }
                    return null;
                };

                const singleDiscountInfo = getDiscountInfo();

                const productComment = !!cartProduct.orderProduct.comment && cartProduct.orderProduct.comment;

                return (
                    <PseudoBox
                        key={cartProduct.id}
                        cursor={isProductItemClickable ? "pointer" : "default"}
                        rounded="md"
                        bg={!isBundleFinished ? "#ffebeb" : "white"}
                        px={{ base: 6, xl: 2, "2xl": 0 }}
                        onClick={onCartListItemClick}
                    >
                        <NewGrid
                            templateColumns="max-content 3fr 1fr"
                            gridColumnGap="1.25em"
                            alignItems="center"
                            templateAreas={`"utilBtns name price"
                            ". specifications specifications" "comment comment comment"`}
                        >
                            <NewGrid area="utilBtns" display="block">
                                <OnlineIconButton
                                    type="button"
                                    fontSize="20px"
                                    icon={IoIosRemove}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        if (orderProduct.quantity > 1) {
                                            decrementOrderProduct(cartProduct);
                                        } else {
                                            removeCartProduct(cartProduct);
                                        }
                                    }}
                                    isDisabled={disabledDecrement}
                                />
                                <OnlineText as="span" mx={isOnlineExpress ? 4 : 2}>
                                    {orderProduct.quantity}
                                </OnlineText>
                                <OnlineIconButton
                                    type="button"
                                    fontSize="20px"
                                    icon={IoIosAdd}
                                    isDisabled={isIncrementBtnDisabled}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        incrementOrderProduct(cartProduct);
                                    }}
                                />
                            </NewGrid>
                            <NewGrid area="name">
                                <Text fontWeight="600" fontSize="lg" color="gray.900" mb={appliedDiscount ? 0 : ""}>
                                    {orderProduct.name}
                                </Text>
                            </NewGrid>
                            <NewGrid area="price" gridTemplateColumns="1fr 1fr" gridGap="0.25rem">
                                {orderProduct.totalPrice !== 0 ? (
                                    <Text
                                        fontWeight="600"
                                        fontSize="lg"
                                        textAlign="right"
                                        color="gray.900"
                                        mb={appliedDiscount ? 0 : ""}
                                    >
                                        {formatSwedishFinancialNumbers(totalProductPrice)}
                                    </Text>
                                ) : (
                                    <Text fontWeight="600" fontSize="lg" textAlign="right" color="green.500">
                                        {translate("free")}
                                    </Text>
                                )}
                            </NewGrid>
                            <NewGrid area="specifications">
                                <OnlineAddonsList mb={2} addons={orderProduct.addons} />
                                {orderProduct.selectedBundleProductItems && (
                                    <BundleProductItems bundleProductItems={orderProduct.selectedBundleProductItems} />
                                )}
                                {orderProduct.modifications && (
                                    <ModificationsList modifications={orderProduct.modifications as any} />
                                )}
                            </NewGrid>
                            {(hasMenuItemDiscounts || hasCombinedDiscounts) && (
                                <NewGrid area="discount" color="red.500" gridColumn="2">
                                    {!hasCombinedDiscounts && singleDiscountInfo ? (
                                        <OnlineText ml={2} textTransform="capitalize">
                                            {translate(singleDiscountInfo.variant)} ({singleDiscountInfo?.name})
                                        </OnlineText>
                                    ) : (
                                        combinedDiscountNames()
                                    )}
                                </NewGrid>
                            )}
                            {productComment && (
                                <NewGrid area="comment">
                                    <OnlineText m={0} fontWeight="bold">
                                        {translate("comment")}
                                    </OnlineText>
                                    <Box
                                        backgroundColor="gray.100"
                                        rounded={3}
                                        padding={2}
                                        marginY={1}
                                        wordBreak="break-word"
                                    >
                                        <OnlineText>{productComment}</OnlineText>
                                    </Box>
                                </NewGrid>
                            )}
                        </NewGrid>
                    </PseudoBox>
                );
            })}
        </Stack>
    );
};
