import styled from "styled-components";

import { BannerImageProps, BannerImage } from "Atoms";

type Props = {
    showMessage?: boolean;
} & BannerImageProps;

export const OnlineBannerImage = styled(BannerImage)<Props>`
    filter: ${props => props.showMessage && "brightness(40%)"};
`;
