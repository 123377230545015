import React from "react";

import { Button, ButtonProps } from "Atoms";
import { useOnline } from "Providers";

type Props = ButtonProps;

export const OnlineButton: React.FC<Props> = props => {
    const { isOnlineExpress } = useOnline();
    const size = isOnlineExpress ? "xl" : "md";

    return <Button size={size} {...props} />;
};
