import React from "react";

import { useOnlineStore } from "Stores";
import { PartnerPromotionImageType } from "Types";
import { Image, ImageProps, Box } from "Atoms";
import { useLanguage } from "Providers";

type Props = {
    categoryType: PartnerPromotionImageType | undefined;
} & Partial<ImageProps>;

export const OnlinePromotionCategory: React.FC<Props> = ({ categoryType, ...rest }) => {
    const { partnerPromotion } = useOnlineStore();
    const { translate } = useLanguage();
    if (!categoryType) return null;

    switch (categoryType) {
        case PartnerPromotionImageType.CATEGORY_AND_PRODUCTS:
            return (
                <>
                    <Box as="span" ml={1} fontSize={rest.fontSize ?? "inherit"}>
                        {translate("withAlt")}
                    </Box>
                    <Image
                        src={partnerPromotion?.partnerProductsImageUrl!}
                        position="absolute"
                        h="27px"
                        w="auto"
                        mt="-5px"
                        ml={1}
                        {...rest}
                    />
                </>
            );
        case PartnerPromotionImageType.CATEGORY:
            return (
                <Image
                    src={partnerPromotion?.promotedCategoryImageUrl!}
                    h="100%"
                    w="auto"
                    top="-2px"
                    right="10"
                    position="absolute"
                    {...rest}
                />
            );
        default:
            const hasAltImageUrl = !!partnerPromotion?.menuCategoryNavigationImageUrl;
            const promotionUrl = hasAltImageUrl
                ? partnerPromotion.menuCategoryNavigationImageUrl
                : partnerPromotion?.promotedCategoryImageUrl;
            return (
                <>
                    <Box as="span" ml={1} fontSize={rest.fontSize ?? "inherit"}>
                        {translate("withAlt")}
                    </Box>
                    <Image src={promotionUrl} position="absolute" h="27px" w="auto" mt="-5px" ml={1} {...rest} />
                </>
            );
    }
};
