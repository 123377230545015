import React from "react";
import { FiTag } from "@react-icons/all-files/fi/FiTag";

import { Box, Flex, Stack, StackProps, Text } from "Atoms";
import { Discount, FixedDiscount } from "Types";
import { useLanguage } from "Providers";
import { formatFinancialNumbers } from "PriceUtils";
import { useQoplaStore } from "Stores";
import { DiscountDisplay } from "OnlineUtils";

type Props = {
    discountsToDisplay: DiscountDisplay[];
    discounts: (FixedDiscount | Discount)[];
    isSubtle?: boolean;
    shouldShowValue?: boolean;
} & StackProps;

export const OnlineActiveDiscounts: React.FC<Props> = ({
    discountsToDisplay,
    discounts,
    isSubtle = false,
    shouldShowValue = true,
    ...rest
}) => {
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();

    return (
        <Stack mb={2} stretch={3} {...rest}>
            {discountsToDisplay.map(discountToDisplay => {
                const singleDiscount = discounts.find(single => {
                    const hasMatchingId = single.id === discountToDisplay?.id;
                    if (discountToDisplay.isSubscriptionDiscount) {
                        const { rate, amount } = single;
                        const hasMatchingAmount = amount && amount === single.amount;
                        const hasMatchingPercentage = rate && rate === single.rate;

                        return hasMatchingId && (hasMatchingAmount || hasMatchingPercentage);
                    } else {
                        return hasMatchingId;
                    }
                });

                const valueText = !discountToDisplay.isSubscriptionDiscount
                    ? singleDiscount?.rate
                        ? ` ${singleDiscount.rate}%`
                        : ` ${formatFinancialNumbers(singleDiscount?.amount ?? 0, companyLocale)}`
                    : "";

                return (
                    <Stack key={discountToDisplay.id}>
                        <Flex justify="space-between" align="flex-start">
                            <Stack isInline stretch={2} align="flex-start" flex={1}>
                                <Box as={FiTag} size={5} color="red.500" mt="0.15rem" />
                                <Box>
                                    <Stack isInline stretch={2}>
                                        <Text mb={0} fontWeight="bold">
                                            <Text as="span">
                                                {translate("discount")} ({discountToDisplay?.name})
                                            </Text>
                                        </Text>
                                        {!!valueText && !isSubtle && (
                                            <Text color="gray.600">
                                                <Text as="span">{valueText}</Text>
                                            </Text>
                                        )}
                                    </Stack>
                                    {discountToDisplay.message && !isSubtle && (
                                        <Text as="span" color="gray.600">
                                            - {discountToDisplay.message}
                                        </Text>
                                    )}
                                </Box>
                            </Stack>
                            {shouldShowValue && (
                                <Text as="span" color="red.500">
                                    - {formatFinancialNumbers(discountToDisplay.value, companyLocale)}
                                </Text>
                            )}
                        </Flex>
                    </Stack>
                );
            })}
        </Stack>
    );
};
