import React from "react";
import Truncate from "react-truncate";

import { Accordion, AccordionContent, AccordionItem, AccordionTitle, AccordionCloseIcon } from "Organisms";
import { Header, Image, Flex, Text, Box } from "Atoms";
import {
    CartProduct,
    CustomerMetaStatus,
    OnlineProduct,
    OnlineProductCategories,
    OnlineProductCategory as OnlineProductCategoryType
} from "Types";
import { OnlineProductCategory } from "./";
import { SubscriptionCountDown } from "./OnlineMenuProductCategoryBanner/components";
import { useOnlineStore, useOrderWindowsStore, useQoplaStore } from "Stores";
import { OnlinePromotionCategory } from "OnlineComponents";

type Props = {
    onlineProductCategories: OnlineProductCategories | undefined;
    noTodayActiveHours: boolean;
    isInactiveMenu?: boolean;
};

export const OnlineMobileCategories: React.FC<Props> = ({
    onlineProductCategories,
    noTodayActiveHours,
    isInactiveMenu
}) => {
    const { activeIndexes, setActiveIndexes } = useOnlineStore();
    const { selectedShop } = useQoplaStore();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();
    const cartProducts = orderWindow?.cartProducts;

    const shopHasPartnerPromotion = !!selectedShop?.settings.partnerPromotionId;

    return (
        <Accordion activeIndexes={activeIndexes} multi onChange={setActiveIndexes as any} rounded="lg">
            {onlineProductCategories?.map(onlineProductCategory => {
                const quantityInCategory =
                    cartProducts
                        ?.filter((cartProduct: CartProduct) => cartProduct.isFinished)
                        ?.filter(
                            (cartProduct: CartProduct) => cartProduct?.customerMeta?.status == CustomerMetaStatus.ADDED
                        )
                        .reduce((acc: number, cartProduct: CartProduct) => {
                            if (onlineProductCategory.id != cartProduct.orderProduct.menuCategoryId) {
                                return acc;
                            }
                            return acc + cartProduct.orderProduct.quantity;
                        }, 0) || 0;
                return (
                    <AccordionItem key={onlineProductCategory.id} ref={onlineProductCategory.ref}>
                        <AccordionTitle minH="65px" alignItems="center" position="relative">
                            {onlineProductCategory.imageUrl && (
                                <Flex>
                                    <Image
                                        alt={onlineProductCategory.name}
                                        src={onlineProductCategory.imageUrl}
                                        h="50px"
                                        w="50px"
                                        objectFit="cover"
                                        rounded="full"
                                    />
                                </Flex>
                            )}
                            <Flex w="100%" direction="column" alignSelf="center">
                                <Flex
                                    p={2}
                                    pr={4}
                                    pl={!onlineProductCategory.imageUrl ? 4 : ""}
                                    w="100%"
                                    justify="space-between"
                                    align="center"
                                >
                                    <Header
                                        as="h4"
                                        fontSize="xl"
                                        m="0"
                                        color="baseDark"
                                        textAlign={onlineProductCategory?.isSubscriptionCategory ? "left" : null}
                                    >
                                        {onlineProductCategory.name}
                                        {shopHasPartnerPromotion && (
                                            <OnlinePromotionCategory
                                                categoryType={
                                                    onlineProductCategory.partnerPromotionImageType ?? undefined
                                                }
                                            />
                                        )}
                                    </Header>
                                </Flex>
                                {onlineProductCategory.description && (
                                    <Text
                                        textAlign="left"
                                        pr={4}
                                        pt={0}
                                        pl={!onlineProductCategory.imageUrl ? 4 : ""}
                                        ml={2}
                                    >
                                        {/** @ts-ignore */}
                                        <Truncate lines={2}>{onlineProductCategory.description}</Truncate>
                                    </Text>
                                )}
                                {onlineProductCategory.isSubscriptionCategory && (
                                    <Box pl={2} textAlign="left">
                                        <SubscriptionCountDown id={onlineProductCategory.id} />
                                    </Box>
                                )}
                            </Flex>
                            <AccordionCloseIcon />
                        </AccordionTitle>
                        <AccordionContent
                            pt={6}
                            px={3}
                            backgroundColor="gray.100"
                            boxShadow="inset 0px 4px 8px 0px rgba(0,0,0,0.2)"
                        >
                            <OnlineProductCategory
                                key={onlineProductCategory.id}
                                onlineProductCategory={onlineProductCategory}
                                isMobile
                                noTodayActiveHours={noTodayActiveHours}
                                isInactiveMenu={isInactiveMenu ?? false}
                                quantityInCategory={quantityInCategory}
                            />
                        </AccordionContent>
                    </AccordionItem>
                );
            })}
        </Accordion>
    );
};
