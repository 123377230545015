import React from "react";

import { Image, ImageProps } from "Atoms";
import { useOnlineStore } from "Stores";

export const OnlinePromotionProduct: React.FC<Partial<ImageProps>> = ({ ...rest }) => {
    const { partnerPromotion } = useOnlineStore();
    const hasPromotion = !!partnerPromotion?.id;

    if (!hasPromotion || !partnerPromotion?.menuProductImageUrl) {
        return null;
    }

    return (
        <Image
            src={partnerPromotion.menuProductImageUrl}
            maxWidth="3rem"
            position="absolute"
            top="0"
            right="0"
            {...rest}
        />
    );
};
