import React from "react";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";

import { Flex } from "Atoms";
import { OnlineIconButton, OnlineText } from "OnlineComponents";

type Props = {
    add: {
        isDisabled: boolean;
        function: (event: React.MouseEvent<any, MouseEvent>) => void;
    };
    decrement: {
        isDisabled: boolean;
        function: (event: React.MouseEvent<any, MouseEvent>) => void;
        color?: string;
    };
    quantity: number;
    onlyShowDecrement?: boolean;
    hasOnlineCtx?: boolean;
};

export const OnlineItemQuantityIndicator: React.FC<Props> = ({
    add,
    decrement,
    quantity,
    onlyShowDecrement,
    hasOnlineCtx = true
}) => (
    <Flex justify="space-between" align="center">
        <Flex align="center">
            <OnlineIconButton
                hasOnlineCtx={hasOnlineCtx}
                icon={FiMinus}
                isDisabled={decrement.isDisabled}
                onClick={decrement.function}
                themeColor={decrement.color || "gray"}
            />
            {!onlyShowDecrement && (
                <>
                    <OnlineText as="span" mx={2} hasOnlineCtx={hasOnlineCtx}>
                        {quantity}
                    </OnlineText>
                    <OnlineIconButton
                        hasOnlineCtx={hasOnlineCtx}
                        icon={FiPlus}
                        isDisabled={add.isDisabled}
                        onClick={add.function}
                    />
                </>
            )}
        </Flex>
    </Flex>
);
