import React from "react";

import { AccordionTitle } from "Organisms";
import { CartProduct, SelectedBundleProductItem } from "Types";
import { Button, Flex, Text } from "Atoms";
import { LanguageText } from "Components";
import { BundleItemIcon } from "./BundleItemIcon";
import { ExtendedBundleProductCategory } from "OnlineTypes";
import { shouldDisplayStockStatusText } from "Utils";
import { OnlineStrikeThroughPrice } from "OnlineComponents";
import { Languagekey } from "Providers";

type Props = {
    bundleCartProduct: CartProduct;
    isOnlineExpress: boolean;
    bundleItem: any;
    onClick: () => void;
    onBundleItemIconClick: () => void;
    priceText: string | null;
    translate: (tid: Languagekey) => string;
    selectedBundleProductItems?: SelectedBundleProductItem[] | null;
    bundleItemStock: number | null;
    isMultiBundleCat: boolean;
    selectedBundleItem: SelectedBundleProductItem | undefined;
    catLimitReached: boolean;
    bundleProductCategory: ExtendedBundleProductCategory;
    subscriptionModificationIsFree: boolean;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
};

export const BundleItemAccordionTitle: React.FC<Props> = ({
    bundleCartProduct,
    isOnlineExpress,
    bundleItem,
    onClick,
    onBundleItemIconClick,
    priceText,
    translate,
    selectedBundleProductItems,
    bundleItemStock,
    selectedBundleItem,
    setBundleCartProduct,
    subscriptionModificationIsFree,
    ...rest
}) => {
    const isAdded = !!selectedBundleItem;
    const isOutOfStock = bundleItem.isOutOfStock || (bundleItemStock != null && bundleItemStock <= 0);

    const _disabled = isAdded ? { cursor: "not-allowed", color: "black" } : { cursor: "not-allowed", opacity: 0.4 };

    if (isOnlineExpress) {
        let styles = {
            themeColor: "gray",
            variant: "outline"
        };

        if (isAdded) {
            styles = {
                themeColor: "green",
                variant: "solid"
            };
        }

        return (
            <Flex px={4}>
                <AccordionTitle
                    paddingLeft={2}
                    disabled={isOutOfStock}
                    _disabled={_disabled}
                    _focus={undefined}
                    as="div"
                >
                    <Flex width="100%">
                        <Flex align="center" flex="1" textAlign="left">
                            <Text as={isOutOfStock ? "del" : "span"} fontSize="xl">
                                {bundleItem.name}
                            </Text>
                            {isOutOfStock && <LanguageText tid="outOfStock" as="span" fontSize="xl" ml={1} />}
                        </Flex>
                        <Text as="span" fontSize="xl">
                            {priceText}
                        </Text>
                        {!isOutOfStock && (
                            <Button size="xl" ml={4} width={125} onClick={onClick} {...(styles as any)}>
                                {!!isAdded ? translate("added") : translate("addTo")}
                            </Button>
                        )}
                    </Flex>
                </AccordionTitle>
            </Flex>
        );
    } else {
        return (
            <Flex my={2} align="center">
                <BundleItemIcon
                    bundleCartProduct={bundleCartProduct}
                    bundleItem={bundleItem}
                    isSelected={isAdded}
                    onAccordionClick={onBundleItemIconClick}
                    setBundleCartProduct={setBundleCartProduct}
                    {...rest}
                />
                <AccordionTitle pl={2} disabled={isOutOfStock} _disabled={_disabled} onClick={onClick}>
                    <Flex width="100%">
                        <Flex align="baseline" flex="1" textAlign="left">
                            <Text as={isOutOfStock ? "del" : "span"} fontSize="lg">
                                {bundleItem.name}
                            </Text>
                            {isOutOfStock && <LanguageText tid="outOfStock" as="span" fontSize="lg" ml={1} />}
                            {shouldDisplayStockStatusText(bundleItemStock) && (
                                <LanguageText
                                    as="span"
                                    tid="fewLeft"
                                    fontSize="sm"
                                    textTransform="uppercase"
                                    letterSpacing="1px"
                                    fontWeight="bold"
                                    color="teal.500"
                                    ml={2}
                                />
                            )}
                        </Flex>
                        {!subscriptionModificationIsFree ? (
                            <Text as="span" fontSize="lg">
                                {priceText}
                            </Text>
                        ) : (
                            <>
                                <OnlineStrikeThroughPrice colour="red.500">
                                    <Text as="span" fontSize="lg">
                                        {priceText}
                                    </Text>
                                </OnlineStrikeThroughPrice>
                                <Text as="span" fontSize="lg" ml={1} color="red.500">
                                    {translate("free")}
                                </Text>
                            </>
                        )}
                    </Flex>
                </AccordionTitle>
            </Flex>
        );
    }
};
