import React from "react";
import { IoIosHeart } from "@react-icons/all-files/io/IoIosHeart";
import { IoIosHeartEmpty } from "@react-icons/all-files/io/IoIosHeartEmpty";

import { IconButton } from "Molecules";
import { Button, ButtonIcon, Skeleton } from "Atoms";
import { useAuthUser, useDrawer, useLanguage, useQopla } from "Providers";
import { useTypedMutation, useTypedQuery } from "Hooks";
import { ADD_SHOP_TO_FAVORITES_MUTATION } from "GraphQLMutations";
import { FIND_USER_ACCOUNTS_FAVORITE_SHOP_IDS } from "GraphQLQueries";
import {
    AddShopToFavoritesMutation,
    AddShopToFavoritesMutationVariables,
    FindFavoriteUserAccountShopIdsQuery,
    FindFavoriteUserAccountShopIdsQueryVariables
} from "../../../../generated/graphql";

type Props = {
    isDesktop: boolean;
};

export const OnlineHeartIconButton: React.FC<Props> = ({ isDesktop }) => {
    const { userAccount } = useAuthUser();
    const { selectedShop } = useQopla();
    const { translate } = useLanguage();
    const { onOpenDrawer } = useDrawer();

    const [addShopToFavorites] = useTypedMutation<AddShopToFavoritesMutation, AddShopToFavoritesMutationVariables>(
        ADD_SHOP_TO_FAVORITES_MUTATION
    );
    const { data, loading, updateQuery } = useTypedQuery<
        FindFavoriteUserAccountShopIdsQuery,
        FindFavoriteUserAccountShopIdsQueryVariables
    >(FIND_USER_ACCOUNTS_FAVORITE_SHOP_IDS, {
        variables: {
            userId: userAccount?.id || ""
        },
        skip: !userAccount
    });

    const handleAddShopToFavorites = async () => {
        if (!userAccount) {
            return onOpenDrawer("customerDrawer");
        }

        const currentShopIds = data?.findFavoriteUserAccountShops?.map(shop => shop.id) ?? [];
        const shouldAdd = !currentShopIds.some(shopId => shopId === selectedShop?.id);

        let newFavorites: string[] = [];
        if (shouldAdd && selectedShop?.id) {
            newFavorites = [...currentShopIds, selectedShop?.id];
        } else {
            newFavorites = currentShopIds.filter(shopId => shopId !== selectedShop?.id);
        }

        const { data: addShopToFavData } = await addShopToFavorites({
            variables: { favoriteShopIds: newFavorites }
        });

        if (addShopToFavData && addShopToFavData.addShopToFavorites) {
            updateQuery(() => ({
                findFavoriteUserAccountShops: newFavorites.map(f => ({ id: f, __typename: "Shop" }))
            }));
        }
    };

    if (loading) {
        return <Skeleton h={12} minW={isDesktop ? 32 : 12} mr={2} />;
    }

    const isFavorite =
        data && data.findFavoriteUserAccountShops
            ? data.findFavoriteUserAccountShops?.some((shop: any) => shop.id === selectedShop?.id)
            : false;
    const HeartIcon = isFavorite ? IoIosHeart : IoIosHeartEmpty;

    if (isDesktop) {
        const text = translate("favorite");

        return (
            <Button
                size="lg"
                variant="outline"
                fontWeight="normal"
                color="gray.700"
                borderColor="gray.600"
                mr={2}
                onClick={handleAddShopToFavorites}
            >
                <ButtonIcon ml={-1} mr={2} color="red.400" size="1.25rem" icon={HeartIcon} />
                {text}
            </Button>
        );
    } else {
        return (
            <IconButton
                icon={HeartIcon}
                variant="outline"
                color="red.400"
                fontSize="1.25rem"
                rounded="lg"
                borderColor="gray.400"
                onClick={handleAddShopToFavorites}
            />
        );
    }
};
