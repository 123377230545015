import React from "react";

import { CartProduct, OnlineProductCategory, RefBundleProduct, RefProduct } from "Types";
import { Modal, ModalHeader, ModalBody, ModalActions, ModalCloseBtn } from "Molecules";
import { Button, Flex, NewGrid as Grid } from "Atoms";
import { useLanguage } from "Providers";
import { useOnline } from "Providers";
import { useOrientation } from "Hooks";
import { OnlineProduct } from "./";
import { OnlineHeader } from "OnlineComponents";
import { getOnlineModalStyles } from "OnlineUtils";
import { useOrderWindowsStore } from "Stores";

type Props = {
    upsellCategory: OnlineProductCategory;
    onContinuePress: () => void;
    onClose: () => void;
};

export const OnlineUpsellModal: React.FC<Props> = ({ upsellCategory, onContinuePress, onClose }) => {
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();
    const cartProducts = orderWindow?.cartProducts;

    const { isOnlineExpress } = useOnline();
    const { translate } = useLanguage();
    const { type } = useOrientation();

    const { modalBodyStyles, modalStyles } = getOnlineModalStyles(isOnlineExpress, type);

    return (
        <Modal
            id="onlineUpsellModal"
            open
            isScrolling
            dimmer={{ inverted: true, strength: "0.5" }}
            {...modalStyles}
            size="xl"
            onClose={onClose}
        >
            <ModalHeader>
                <OnlineHeader size="md" m="0">
                    {translate("desireSomethingMore")}
                </OnlineHeader>
                <ModalCloseBtn size={isOnlineExpress ? "touch" : "md"} />
            </ModalHeader>
            <ModalBody backgroundColor="gray.100" {...modalBodyStyles}>
                <Grid
                    templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
                    gap={6}
                >
                    {upsellCategory.onlineProducts.map(onlineProduct => {
                        const isDisabled = [onlineProduct.isOutOfStock].some(value => value);

                        const product = onlineProduct.refProduct
                            ? (onlineProduct.refProduct as RefProduct)
                            : (onlineProduct.refBundleProduct as RefBundleProduct);
                        const productIsInCart = cartProducts?.find((value: CartProduct) => {
                            const cartMenuProduct =
                                value.menuProduct?.refProduct || value.menuBundleProduct?.refBundleProduct;
                            return product?.id === cartMenuProduct?.id;
                        });
                        return (
                            <OnlineProduct
                                key={onlineProduct.id}
                                onlineProduct={onlineProduct}
                                menuProductCategoryId={upsellCategory.id}
                                isDisabled={isDisabled}
                                isUpsell
                                quantityInCart={productIsInCart?.orderProduct?.quantity || 0}
                            />
                        );
                    })}
                </Grid>
            </ModalBody>

            <ModalActions>
                <Flex justifyContent="flex-end">
                    <Button
                        size={isOnlineExpress ? "xl" : "md"}
                        onClick={onContinuePress}
                        backgroundColor="newPrimary"
                        color="newPrimaryFont"
                        themeColor="green"
                    >
                        {translate("continue")}
                    </Button>
                </Flex>
            </ModalActions>
        </Modal>
    );
};
