import React from "react";

import { Flex, Header, HeaderSizes, Text } from "Atoms";
import { useLanguage } from "Providers";
import { LanguageHeader } from "Components";
import { OnlineHeaderMessage as OnlineHeaderMessageType } from "OnlineTypes";
import { useQoplaStore } from "Stores";
import { OnlineSettings } from "Types";

type Props = {
    headerMsg: OnlineHeaderMessageType;
    isMobile: boolean;
    size?: HeaderSizes;
};

export const OnlineHeaderMessage: React.FC<Props> = ({ headerMsg, isMobile, size }) => {
    const { dictionary } = useLanguage();
    const { selectedShop: shop } = useQoplaStore();
    const { translate } = useLanguage();

    const onlineSettings = shop?.settings?.onlineSettings as OnlineSettings;
    const headerSize = isMobile ? "lg" : size ? size : "xl";
    if (!!headerMsg) {
        if (headerMsg.isCustom) {
            return (
                <Flex justify="center" align="center" direction="column" textAlign="center">
                    <Header color="white" size={headerSize}>
                        {headerMsg.titleKey}
                    </Header>
                </Flex>
            );
        } else {
            let text;

            if (headerMsg.textKey) {
                const isAFullSentence = /\s/g.test(headerMsg.textKey);
                if (isAFullSentence) {
                    text = headerMsg.textKey;
                } else {
                    const translatedText = translate(headerMsg.textKey);
                    text = translate("translationMissing") === translatedText ? headerMsg.textKey : translatedText;
                }
            }

            return (
                <Flex justify="center" align="center" direction="column" textAlign="center">
                    {headerMsg.titleKey &&
                        (headerMsg.titleKey === "rushHourTitle" ? (
                            <Header color="white" size={headerSize}>
                                {dictionary[headerMsg.titleKey]}
                                {!!onlineSettings.rushHourWaitMinutes &&
                                    " - " + dictionary["approx"] + " " + onlineSettings.rushHourWaitMinutes + " min"}
                            </Header>
                        ) : (
                            <LanguageHeader tid={headerMsg.titleKey} color="white" size={headerSize} />
                        ))}
                    {text && (
                        <Text color="white" maxWidth="80%" fontSize={!!size ? "large" : "medium"} textAlign="center">
                            {text}
                        </Text>
                    )}
                </Flex>
            );
        }
    }
    return null;
};
