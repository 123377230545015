import React, { PropsWithChildren } from "react";

import { PseudoBox } from "Atoms";

type Props = {
    colour?: string;
    marginLeft?: string;
    paddingLeft?: string;
} & PropsWithChildren;

const priceStrikeThroughStyle: any = (styleProps: Props) => {
    return {
        position: "relative",
        paddingLeft: styleProps.paddingLeft ? styleProps.paddingLeft : "",
        _before: {
            content: JSON.stringify(""),
            marginLeft: styleProps.marginLeft ? styleProps.marginLeft : "",
            display: "block",
            width: "100%",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderStyle: "solid",
            color: styleProps.colour ? styleProps.colour : "red.500",
            position: "absolute",
            bottom: "2",
            right: "2",
            transform: "rotate(-15deg)"
        }
    };
};

export const OnlineStrikeThroughPrice: React.FC<Props> = ({ children, ...props }) => {
    return (
        <PseudoBox as="div" {...priceStrikeThroughStyle(props)}>
            {children}
        </PseudoBox>
    );
};
