import React from "react";

import { useLanguage } from "Providers";
import { useOrderWindowsStore } from "Stores";
import { CartProduct } from "Types";
import { Stack } from "Atoms";
import { OnlineText } from "./OnlineText";

type Props = {
    cartProduct: CartProduct;
};

export const OnlineCheckoutCartProductCombinedDiscounts: React.FC<Props> = ({ cartProduct }) => {
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const { translate } = useLanguage();

    const orderWindow = getActiveOrderWindow();

    const fixedDiscount = orderWindow?.fixedDiscounts?.find(
        discount => discount.id === cartProduct.fixedDiscount?.fixedDiscountId
    );

    if (fixedDiscount && orderWindow?.discount?.name) {
        return (
            <Stack color="red.500" stretch={2}>
                <OnlineText margin="0" marginLeft="0.25rem">
                    {`${translate("discount")} (${fixedDiscount.name})`}
                </OnlineText>
                <OnlineText margin="0" marginLeft="0.25rem">
                    {`${translate("discount")} (${orderWindow?.discount.name})`}
                </OnlineText>
            </Stack>
        );
    }

    return null;
};
