import React, { PropsWithChildren } from "react";

import { Flex, Header, Box, NewContainer } from "Atoms";

type Props = {
    icon: React.ComponentType;
    headerText: string;
} & PropsWithChildren;

export const OnlineInformationContainer: React.FC<Props> = ({ icon, headerText, children }) => {
    return (
        <NewContainer height="100%">
            <Flex direction="column" justify="center" align="center">
                <Box size={32} as={icon} />
                <Header textAlign="center">{headerText}</Header>
                {children && children}
            </Flex>
        </NewContainer>
    );
};
