import React from "react";
import { useMedia } from "react-media";

import { NewIconButton, NewIconButtonProps } from "Molecules";
import { useOnline } from "Providers";
import { GLOBAL_BREAK_POINTS } from "Constants";

type Props = { hasOnlineCtx?: boolean } & NewIconButtonProps;

export const OnlineIconButton: React.FC<Props> = ({ hasOnlineCtx = true, ...props }) => {
    const isXLScreen = useMedia({ query: GLOBAL_BREAK_POINTS.XL });
    let size = "md";
    if (hasOnlineCtx) {
        const { isOnlineExpress } = useOnline();
        if (isOnlineExpress) size = isXLScreen ? "lg" : "xl";
    }

    // TODO fix
    // @ts-ignore
    return <NewIconButton size={size} {...props} />;
};
