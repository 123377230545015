import React, { useState, useEffect } from "react";

import { isNegative, isOutOfStock, isHideFromStock } from "Utils";
import { useSwitch } from "Hooks";
import { LanguageText } from "Components";
import { useOnline, usePos } from "Providers";
import { Flex, Box } from "Atoms";
import { Addon, ProductIngredientGroup, IngredientLimiter } from "Types";
import { Accordion, AccordionContent, AccordionItem, AccordionTitle, AccordionCloseIcon } from "Organisms";
import {
    getActiveAddonsIndexes,
    addonsToShow,
    getSelectedAddon,
    canHaveManyOfAddon
} from "../utils/onlineOrderAddonsListUtils";
import { OnlineItemQuantityIndicator } from "OnlineComponents";
import { OnlineAddonsGroupHeader, OnlineAddonsGroupAccordionTitle } from ".";
import { AddonGroupsLevel } from "../OnlineAddonsGroups";

type Props = {
    productAddonGroup: ProductIngredientGroup;
    selectedAddons: Addon[];
    onAddAddon: (addon: Addon) => void;
    onIncrementAddon: (addon: Addon) => void;
    onDecrementAddon: (addon: Addon) => void;
    onResetAddons: (addon: Addon) => void;
    productName: string;
    level: AddonGroupsLevel;
    showHeader: boolean;
};

export const OnlineAddonsGroup: React.FC<Props> = ({
    productAddonGroup,
    selectedAddons,
    onAddAddon,
    onDecrementAddon,
    onIncrementAddon,
    onResetAddons,
    showHeader,
    level
}) => {
    const [activeIndexes, setActiveIndexes] = useState<number[]>(() =>
        getActiveAddonsIndexes(selectedAddons, productAddonGroup)
    );
    const [countQuantiy, setCountQuantiy] = useState(0);

    const { open, onToggle } = useSwitch();
    const { outOfStockIds, hideFromStockIds } = usePos();
    const { isOnlineExpress } = useOnline();

    useEffect(() => {
        setActiveIndexes(getActiveAddonsIndexes(selectedAddons, productAddonGroup));
    }, [selectedAddons]);

    const accordionItemIsOpen = (index: number) => activeIndexes.includes(index);

    const handleSetActiveIndexes = (newIndex: number) => {
        setActiveIndexes(currIndexes => {
            const isOpen = currIndexes.includes(newIndex);
            if (isOpen) {
                return currIndexes.filter(index => index !== newIndex);
            }
            return currIndexes.concat(newIndex);
        });
    };

    const onAccordionItemClick = (ingredientLimiter: IngredientLimiter, index: number) => {
        const { ingredient: addon } = ingredientLimiter;

        if (!canHaveManyOfAddon(ingredientLimiter)) {
            if (getSelectedAddon(addon, selectedAddons)) {
                return onResetAddons(addon);
            } else {
                return onAddAddon(addon);
            }
        } else {
            if (!accordionItemIsOpen(index)) {
                if (!getSelectedAddon(addon, selectedAddons)) {
                    onAddAddon(addon);
                    return handleSetActiveIndexes(index);
                } else {
                    onIncrementAddon(addon);
                    return handleSetActiveIndexes(index);
                }
            }
        }
    };

    const showName = productAddonGroup.displayByName;
    const maxAddonsToShow = 5;

    const sortedIngredients = productAddonGroup.ingredients;
    const isOverLimit = sortedIngredients.length > maxAddonsToShow;
    const toShow = addonsToShow(sortedIngredients, isOverLimit, open, maxAddonsToShow);
    const moreAddonsCount = sortedIngredients.length - toShow.length;
    const ingredientLimit = productAddonGroup.ingredients.filter(item => item.limit > 0);
    const isGroupLimit = ingredientLimit.length == 0;

    return (
        <Box marginY={8}>
            <OnlineAddonsGroupHeader
                level={level}
                addonGroupName={productAddonGroup.name}
                companySetting={showName}
                showHeader={showHeader}
                limit={productAddonGroup.limit}
            />
            <Accordion multi activeIndexes={activeIndexes}>
                {toShow.map((ingredientLimiter, i) => {
                    const { ingredient, limit } = ingredientLimiter;
                    const selectedAddon = getSelectedAddon(ingredient, selectedAddons);
                    const selectedAddonQuantity = selectedAddon ? selectedAddon.quantity : 0;
                    const isIncrementDisabled = selectedAddonQuantity === limit || isNegative(ingredient.price);
                    const outOfStock = isOutOfStock(ingredientLimiter.ingredient.name.toLowerCase(), outOfStockIds);
                    const hideStock = isHideFromStock(
                        ingredientLimiter.ingredient.name.toLowerCase(),
                        hideFromStockIds
                    );
                    const toggleAddons = () => {
                        if (!!selectedAddon) {
                            isGroupLimit && setCountQuantiy((prevState: number) => prevState - selectedAddonQuantity);
                            return onResetAddons(ingredient);
                        }
                        if (!outOfStock) {
                            if (isGroupLimit) {
                                if (productAddonGroup.limit == 0 || countQuantiy != productAddonGroup.limit) {
                                    onAccordionItemClick(ingredientLimiter, i);
                                    setCountQuantiy((prevState: number) => prevState + 1);
                                }
                            } else {
                                onAccordionItemClick(ingredientLimiter, i);
                            }
                        }
                    };

                    return (
                        !hideStock && (
                            <AccordionItem key={i}>
                                <OnlineAddonsGroupAccordionTitle
                                    outOfStock={outOfStock}
                                    isOnlineExpress={isOnlineExpress}
                                    ingredient={ingredient}
                                    isSelected={!!selectedAddon}
                                    onClick={toggleAddons}
                                />
                                {productAddonGroup.limit !== 1 && (
                                    <AccordionContent>
                                        <Flex justify="flex-end">
                                            <OnlineItemQuantityIndicator
                                                quantity={selectedAddonQuantity}
                                                add={{
                                                    function: () => {
                                                        onIncrementAddon(ingredient);
                                                        isGroupLimit &&
                                                            setCountQuantiy((prevState: number) => prevState + 1);
                                                    },
                                                    isDisabled:
                                                        isIncrementDisabled ||
                                                        (isGroupLimit &&
                                                            productAddonGroup.limit != 0 &&
                                                            countQuantiy == productAddonGroup.limit)
                                                }}
                                                decrement={{
                                                    function: () => {
                                                        if (selectedAddonQuantity === 1) {
                                                            isGroupLimit &&
                                                                setCountQuantiy((prevState: number) => prevState - 1);
                                                            return onResetAddons(ingredient);
                                                        }
                                                        isGroupLimit &&
                                                            setCountQuantiy((prevState: number) => prevState - 1);
                                                        onDecrementAddon(ingredient);
                                                    },
                                                    isDisabled: !selectedAddonQuantity
                                                }}
                                            />
                                        </Flex>
                                    </AccordionContent>
                                )}
                            </AccordionItem>
                        )
                    );
                })}
                {isOverLimit && !open && (
                    <AccordionItem>
                        <AccordionTitle onClick={onToggle} color="green.600">
                            <Box flex="1" textAlign="left">
                                <LanguageText tid="showMore" as="span" /> ({moreAddonsCount})
                            </Box>
                            <AccordionCloseIcon />
                        </AccordionTitle>
                    </AccordionItem>
                )}
            </Accordion>
        </Box>
    );
};
