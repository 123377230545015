import React from "react";

import { Flex, Stack, Text } from "Atoms";
import { LanguageText } from "Components";
import { Discount, FixedDiscount } from "Types";
import { getCartTotalOriginalPrice } from "../../../../admin/components/poses/pos/utils";
import { useOrderWindowsStore, useQoplaStore } from "Stores";
import { DiscountDisplay, getAllDiscountValues, getOrderWindowDiscounts, mergeOnlineDiscounts } from "OnlineUtils";
import { formatFinancialNumbers } from "PriceUtils";
import { OnlineActiveDiscounts } from "OnlineComponents";

export const OnlineDiscountSection: React.FC<{}> = () => {
    const { companyLocale } = useQoplaStore();
    const { getActiveOrderWindow } = useOrderWindowsStore();

    const orderWindow = getActiveOrderWindow();

    if (!orderWindow) {
        return null;
    }

    const allDiscountValues: DiscountDisplay[] = getAllDiscountValues(orderWindow);
    const discounts: (Discount | FixedDiscount)[] = getOrderWindowDiscounts(orderWindow);
    const discountsToDisplay: DiscountDisplay[] = mergeOnlineDiscounts(allDiscountValues);

    const cartTotalOriginalPrice = formatFinancialNumbers(
        getCartTotalOriginalPrice(orderWindow.cartProducts),
        companyLocale
    );

    return (
        <Stack stretch={3} mb={3}>
            <Flex justify="space-between">
                <LanguageText tid="originalPrice" m="0" color="gray.600" />
                <Text m="0">{cartTotalOriginalPrice}</Text>
            </Flex>
            <OnlineActiveDiscounts discountsToDisplay={discountsToDisplay} discounts={discounts} />
        </Stack>
    );
};
