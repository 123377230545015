import React from "react";

import { BaseBoxProps, Box, Flex, FlexProps, Header } from "Atoms";
import { LanguageText } from "Components";
import { OnlineHeaderMessage } from "OnlineTypes";

const AbsoluteBox: React.FC<BaseBoxProps> = props => <Box position="absolute" {...props} />;
const FlexBox: React.FC<FlexProps> = props => (
    <Flex justify="center" align="center" direction="column" textAlign="center" {...props} />
);

export const OnlineTableName: React.FC<{ tableName?: string; headerMsg?: OnlineHeaderMessage }> = ({
    tableName,
    headerMsg
}) => {
    if (!tableName) {
        return null;
    }

    if (!!tableName && !headerMsg) {
        return (
            <AbsoluteBox top={0} bottom={0} left={0} right={0}>
                <FlexBox>
                    <Box p={4} backgroundColor={"rgba(26,32,44, 0.8)"} borderRadius="10px" wordBreak="break-all">
                        <LanguageText tid="youreingTo" mb={0} color="white" />
                        <Header mt={0} size="2xl" color="white">
                            {tableName}
                        </Header>
                    </Box>
                </FlexBox>
            </AbsoluteBox>
        );
    }

    if (!!tableName && !!headerMsg) {
        return (
            <AbsoluteBox left={0} right={0} top={0}>
                <FlexBox>
                    <Box
                        px={4}
                        py={2}
                        borderBottomLeftRadius={10}
                        borderBottomRightRadius={10}
                        backgroundColor={"rgba(26,32,44, 0.8)"}
                        wordBreak="break-all"
                    >
                        <LanguageText tid="youreingTo" mb={0} color="white" />
                        <Header mt={0} size="xl" color="white">
                            {tableName}
                        </Header>
                    </Box>
                </FlexBox>
            </AbsoluteBox>
        );
    }

    return null;
};
