import React, { useEffect } from "react";
import { useQuery } from "react-apollo";
import { toast, ToastContainer } from "react-toastify";

import { useQopla } from "Providers";
import { GET_QOPLA_MESSAGE_BY_ID } from "GraphQLQueries";
import { TableMode } from "Types";
import { OnlineBannerImage, OnlineHeaderMessage, OnlineInfoCard } from ".";
import { NewGrid as Grid, Box, Flex } from "Atoms";
import { OnlineHeaderMessage as OnlineHeaderMessageType } from "OnlineTypes";
import { OnlinePromotionHeader } from "OnlineComponents";

type Props = {
    restaurant: any;
    addressInfo: string;
    headerMsg: OnlineHeaderMessageType;
    systemMessageToast: Function;
    table: TableMode | null;
};

export const OnlineHeaderDesktop: React.FC<Props> = ({
    restaurant,
    addressInfo,
    headerMsg,
    systemMessageToast,
    table
}) => {
    const {
        globalQoplaObject: { systemMessage },
        setGlobalQoplaObject
    } = useQopla();

    const { data, loading } = useQuery(GET_QOPLA_MESSAGE_BY_ID, {
        fetchPolicy: "network-only",
        variables: {
            messageId: systemMessage.messageId
        }
    });

    useEffect(() => {
        if (loading || !data || !data.getQoplaMessageById) {
            return;
        }
        setGlobalQoplaObject(curr => ({
            ...curr,
            systemMessage: {
                ...curr.systemMessage,
                message: data.getQoplaMessageById
            }
        }));
        systemMessageToast(systemMessage, data.getQoplaMessageById, toast);
    }, [loading]);

    return (
        <>
            <Flex padding="0 !important" height="20rem" align="center" width="100%" position="relative">
                <OnlineBannerImage showMessage={!!headerMsg} backgroundImage={restaurant.bannerUrl} />
                <OnlinePromotionHeader device="desktop" />
                <Grid
                    height="auto"
                    templateColumns="max-content 1fr"
                    paddingX={32}
                    position="absolute"
                    alignItems="center"
                >
                    <OnlineInfoCard restaurant={restaurant} addressInfo={addressInfo} table={table} />
                    <Box data-nosnippet>
                        <OnlineHeaderMessage headerMsg={headerMsg} isMobile={false} />
                    </Box>
                </Grid>
            </Flex>
        </>
    );
};
