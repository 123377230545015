import React from "react";

import { getDate, multiLangDaysOfWeek } from "Utils";
import { ActiveHour } from "Types";
import { useLanguage } from "Providers";
import { List, ListItem, Text } from "Atoms";

type Props = {
    activeHours: ActiveHour[];
};

export const OnlineOpeningHours: React.FC<Props> = ({ activeHours }) => {
    const { translate, userLanguage } = useLanguage();

    const dateToday = getDate("dddd").toUpperCase();
    const daysToUse = multiLangDaysOfWeek[userLanguage];

    const openingHours = daysToUse.reduce((acc: Array<{ dayOfWeek: string; time: string }>, next) => {
        const day = activeHours.find(activeHour => activeHour.dayOfWeek === next.dayOfWeek);
        const dateText = dateToday === next.dayOfWeek ? `(${translate("today")})` : "";
        const dayOfWeek = `${next.swe} ${dateText}`;

        if (day !== undefined) {
            acc.push({
                dayOfWeek,
                time: `${day.startingHour} - ${day.stoppingHour}`
            });
        } else {
            acc.push({ dayOfWeek, time: translate("closed") });
        }

        return acc;
    }, []);

    return (
        <List stretch={2}>
            {openingHours.map(({ dayOfWeek, time }) => (
                <ListItem display="flex" justifyContent="space-between" key={dayOfWeek}>
                    <Text as="span">{dayOfWeek}</Text>
                    <Text as="span">{time}</Text>
                </ListItem>
            ))}
        </List>
    );
};
