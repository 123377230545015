import React, { useState } from "react";
import Media from "react-media";

import { Box, Center, Stack, Text, Emoji } from "Atoms";
import { OnlineMobileCategories, OnlineProductCategory } from "./components";
import { useOnlineStore, useOrderWindowsStore } from "Stores";
import { ProductsSearchInput } from "Molecules";
import { useLanguage } from "Providers";
import {
    getOnlineProductCategoryFromSearchedProducts,
    getOnlineProductQuantitiesInOnlineCategory,
    getPromotedProductIds
} from "../../utils/onlineUtils";

export const OnlineMenuProductCategories: React.FC = ({}) => {
    const [productTextSearchValue, setProductTextSearchValue] = useState("");

    const { translate } = useLanguage();
    const { activeMenu } = useOnlineStore();
    const { getActiveOrderWindow } = useOrderWindowsStore();
    const orderWindow = getActiveOrderWindow();
    const cartProducts = orderWindow?.cartProducts;

    const onlineMenu = activeMenu;

    const hasProductTextSearchValue = !!productTextSearchValue;
    const hasOnlineCategories = onlineMenu?.onlineProductCategories && onlineMenu.onlineProductCategories.length;

    const searchedOnlineProductCategory = hasProductTextSearchValue
        ? getOnlineProductCategoryFromSearchedProducts(
              onlineMenu?.onlineProductCategories ?? [],
              productTextSearchValue
          )
        : null;
    const quantityInSearchedOnlineProductCategory = searchedOnlineProductCategory
        ? getOnlineProductQuantitiesInOnlineCategory(cartProducts ?? [], searchedOnlineProductCategory)
        : 0;

    const searchedPromotedProducts =
        hasProductTextSearchValue && hasOnlineCategories
            ? getPromotedProductIds(onlineMenu?.onlineProductCategories)
            : [];

    return (
        <Box>
            <Box>
                <ProductsSearchInput
                    value={productTextSearchValue}
                    setValue={setProductTextSearchValue}
                    inputSize="lg"
                    buttonSize="sm"
                    mb={4}
                />
            </Box>

            {!!searchedOnlineProductCategory ? (
                <Box>
                    {!searchedOnlineProductCategory.onlineProducts.length ? (
                        <Center flexDirection="column">
                            <Emoji symbol="😢" m="0" fontSize="5xl" />
                            <Text fontSize="lg">
                                {translate("noHitsOn")} "{productTextSearchValue}"
                            </Text>
                        </Center>
                    ) : (
                        <OnlineProductCategory
                            onlineProductCategory={searchedOnlineProductCategory}
                            isMobile
                            noTodayActiveHours={!onlineMenu?.todayActiveHours}
                            isInactiveMenu={!onlineMenu?.todayActiveHours}
                            quantityInCategory={quantityInSearchedOnlineProductCategory}
                            promotedProductIds={searchedPromotedProducts}
                        />
                    )}
                </Box>
            ) : (
                <Media queries={{ small: { maxWidth: 767 } }}>
                    {matches =>
                        matches.small ? (
                            <OnlineMobileCategories
                                onlineProductCategories={onlineMenu?.onlineProductCategories}
                                noTodayActiveHours={!onlineMenu?.todayActiveHours}
                                isInactiveMenu={onlineMenu?.isInactive ?? false}
                            />
                        ) : (
                            <Stack stretch={8}>
                                {onlineMenu?.onlineProductCategories.map(onlineProductCategory => {
                                    const quantityInCategory = getOnlineProductQuantitiesInOnlineCategory(
                                        cartProducts ?? [],
                                        onlineProductCategory
                                    );
                                    
                                    return (
                                        <OnlineProductCategory
                                            key={onlineProductCategory.id}
                                            onlineProductCategory={onlineProductCategory}
                                            isMobile={false}
                                            noTodayActiveHours={!onlineMenu.todayActiveHours}
                                            isInactiveMenu={onlineMenu.isInactive ?? false}
                                            quantityInCategory={quantityInCategory}
                                        />
                                    );
                                })}
                            </Stack>
                        )
                    }
                </Media>
            )}
        </Box>
    );
};
