import React from "react";

import { Text, BaseBoxProps } from "Atoms";
import { useOnline } from "Providers";

type Props = {
    hasOnlineCtx?: boolean;
} & BaseBoxProps;

export const OnlineText: React.FC<Props> = ({ hasOnlineCtx = true, ...props }) => {
    let fontSize = "md";
    if (hasOnlineCtx) {
        const { isOnlineExpress } = useOnline();
        if (isOnlineExpress) fontSize = "xl"
    }

    return <Text fontSize={fontSize} {...props} />;
};
