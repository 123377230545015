import React, { useEffect } from "react";
import { useQuery } from "react-apollo";
import { toast } from "react-toastify";

import { Flex, Image, Box, Header, Text, Icon, Link } from "Atoms";
import { useModal, useQopla, useLanguage } from "Providers";
import { modalNames } from "Constants";
import { GET_QOPLA_MESSAGE_BY_ID } from "GraphQLQueries";
import { TableMode } from "Types";
import { LanguageText } from "Components";
import { OnlineHeaderMessage as OnlineHeaderMessageType } from "OnlineTypes";
import { OnlineHeaderMessage } from ".";
import { OnlineOpeningHour, OnlineHeartIconButton, OnlinePromotionHeader } from "OnlineComponents";
import { useOnlineStore } from "Stores";
import { OnlineTableName } from "./OnlineTableName";

type Props = {
    restaurant: any;
    addressInfo: string;
    headerMsg: OnlineHeaderMessageType;
    systemMessageToast: Function;
    table: TableMode | null;
};

export const OnlineHeaderMobile: React.FC<Props> = ({
    restaurant,
    addressInfo,
    headerMsg,
    systemMessageToast,
    table
}) => {
    const { openModal } = useModal();
    const { translate } = useLanguage();
    const {
        globalQoplaObject: { systemMessage },
        setGlobalQoplaObject
    } = useQopla();
    const { partnerPromotion } = useOnlineStore();
    const {
        activeHours,
        settings: {
            onlineSettings: { closeOnlines }
        },
        contactInformation
    } = restaurant;

    const { data, loading } = useQuery(GET_QOPLA_MESSAGE_BY_ID, {
        fetchPolicy: "network-only",
        variables: {
            messageId: systemMessage.messageId
        }
    });

    useEffect(() => {
        if (loading || !data || !data.getQoplaMessageById) {
            return;
        }
        setGlobalQoplaObject(curr => ({
            ...curr,
            systemMessage: {
                ...curr.systemMessage,
                message: data.getQoplaMessageById
            }
        }));
        systemMessageToast(systemMessage, data.getQoplaMessageById, toast);
    }, [loading]);

    const hasPromotion = !!partnerPromotion?.id;
    const changeHeaderMessagePosition = hasPromotion && !!headerMsg;
    const headerMessagePosition = changeHeaderMessagePosition
        ? { top: 5, zIndex: 11 }
        : { top: 0, bottom: 0, left: 0, right: 0 };

    return (
        <>
            <Box width="100%" padding="0 !important" backgroundColor="white">
                <Box position="relative" height="15rem">
                    <Box overflow="hidden" position="relative" height="15rem">
                        <Image
                            src={restaurant.bannerUrl}
                            position="absolute"
                            top="-9999px"
                            right="-9999px"
                            left="-9999px"
                            objectFit="cover"
                            bottom="-9999px"
                            m="auto"
                            minWidth="100%"
                            minHeight="100%"
                            filter={!!headerMsg ? "brightness(40%)" : ""}
                        />
                        <OnlinePromotionHeader device="mobile" />
                    </Box>
                    <OnlineTableName tableName={table?.name} headerMsg={headerMsg}/>
                    {headerMsg && (
                        <Box position="absolute" {...headerMessagePosition}>
                            <OnlineHeaderMessage headerMsg={headerMsg} isMobile={true} />
                        </Box>
                    )}
                </Box>
                <Flex padding={4} direction="column" align="flex-start" h="auto">
                    <Flex align="center" w="100%">
                        <Image src={restaurant.imageUrl} width="100px" height="100px" objectFit="contain" />
                        <Flex direction="column" align="flex-start" justify="center" ml={2} flex={1}>
                            <Header as="h1" textAlign="left">
                                {restaurant.name}
                            </Header>
                            <Flex justify="space-between" w="100%">
                                <Box>
                                    {!!contactInformation.email && contactInformation.email !== "" && (
                                        <Text as="span">
                                            <Icon name="FaEnvelope" color="#666" size="tiny" />
                                            <Link
                                                href={`mailto:${contactInformation.email}?subject=Hello`}
                                                target="_blank"
                                            >
                                                {translate("contactRestaurant")}
                                            </Link>
                                        </Text>
                                    )}
                                    <OnlineOpeningHour activeHours={activeHours} />
                                    <LanguageText
                                        tid="placeAndOpeningHours"
                                        textDecoration="underline"
                                        margin="0.25rem 0 0 0"
                                        onClick={() =>
                                            openModal(modalNames.ONLINE_INFO_MODAL, {
                                                addressInfo,
                                                activeHours,
                                                closeOnlines,
                                                restaurantName: restaurant.name
                                            })
                                        }
                                    />
                                </Box>
                                <OnlineHeartIconButton isDesktop={false} />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};
