import React from "react";

import { Header, HeaderProps, HeaderSizes } from "Atoms";
import { useOnline } from "Providers";

type Props = {
    size?: HeaderSizes;
} & Omit<HeaderProps, "size">;

const headSizes = {
    "4xl": ["6xl", null, "5rem"],
    "3xl": ["5xl", null, "6xl"],
    "2xl": ["4xl", null, "5xl"],
    xl: ["3xl", null, "4xl"],
    lg: ["xl", null, "2xl"],
    md: "xl",
    sm: "md",
    xs: "sm"
};

 const expressOnlineSizes = {
    xs: headSizes.sm,
    sm: headSizes.md,
    md: headSizes.lg,
    lg: headSizes.xl,
    xl: headSizes["2xl"],
    "2xl": headSizes["3xl"],
    "3xl": headSizes["4xl"]
}; 

export const OnlineHeader: React.FC<Props> = ({ size, ...rest }) => {
    const { isOnlineExpress } = useOnline();

    let _size;

    if (isOnlineExpress && size) {
        //@ts-ignore
        _size = expressOnlineSizes[size];
    } else {
        _size = size;
    }

    return <Header size={_size} {...rest} />;
};
