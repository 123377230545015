import React from "react";

import { usePos, BundleItemOperation, useOnline } from "Providers";
import { BundleItemContent } from "./BundleItemContent";
import { Modification, SelectedBundleProductItem, Addon, CartProduct } from "Types";
import { AvailableBundleItem } from "./CustomerBundleCategory";
import { getAddedBundleItem } from "../utils";
import { Box } from "Atoms";

type Props = {
    bundleCartProduct: CartProduct;
    bundleItem: AvailableBundleItem;
    selectedBundleProductItems?: SelectedBundleProductItem[] | null;
    onAddNewBundleItem: (
        modifiedBundleItem: SelectedBundleProductItem,
        operation?: BundleItemOperation.ADD | BundleItemOperation.REPLACE | undefined,
        bundleItemAddons?: Addon[] | undefined
    ) => void;
    isBundleCategoryLimitReached: boolean;
    onSelectModification: (modificationKey: string, modificationToAdd: Modification, bundleItemIndex: number) => void;
    onRemoveBundleItem: (
        bundleItem: AvailableBundleItem,
        bundleItemAccordionIndex: number,
        closeAccordionItem: boolean
    ) => void;
    bundleItemAccordionIndex: number;
    shouldShowQuantityIndicator: boolean;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
};

export const BundleItemAccordionContent: React.FC<Props> = ({
    bundleCartProduct,
    bundleItem,
    selectedBundleProductItems,
    onAddNewBundleItem,
    isBundleCategoryLimitReached,
    onSelectModification,
    onRemoveBundleItem,
    bundleItemAccordionIndex,
    shouldShowQuantityIndicator,
    setBundleCartProduct
}) => {
    const { addBundleItemToBundleProduct, addons, addAddonToBundleItem } = usePos();
    const { isOnlineExpress } = useOnline();

    const bundleItemAddons = addons[bundleItem.id];
    /*
        Each bundleItem has quantity. But our selectedBundleProductItems don't hold quantity.
        We have a flat array of products: [Rice, Rice, Fried Rice]
        By filtering and checking length of Rice we know its 2 Rices.

        The issue with this is we need to know which INDEX to use before we upsert a selectedBundleProductItem.
        Therefor we are looping trough a bundle item x times based on its quantity field in order to get the correct index

        We would loop through rice twice, since its quantity is 2.
    */

    let _bundleItems: AvailableBundleItem[] = [];
    [...Array(bundleItem.quantity)].forEach(x => _bundleItems.push(bundleItem));

    return (
        <Box>
            {_bundleItems.map((_bundleItem: AvailableBundleItem, innerIndex) => {
                const selectedBundleProductItemIndex = selectedBundleProductItems?.findIndex(
                    selectedBundleProductItem => selectedBundleProductItem.refProductId === _bundleItem.id
                );
                const bundleItemIndex = (selectedBundleProductItemIndex || 0) + innerIndex;
                const selectedBundleItem = getAddedBundleItem(
                    _bundleItem,
                    bundleItemIndex,
                    selectedBundleProductItems!
                );

                return (
                    <BundleItemContent
                        bundleCartProduct={bundleCartProduct}
                        key={innerIndex}
                        bundleItem={_bundleItem}
                        bundleItemInnerIndex={innerIndex}
                        bundleItemAddons={bundleItemAddons}
                        selectedBundleItem={selectedBundleItem}
                        onAddNewBundleItem={onAddNewBundleItem}
                        onRemoveBundleItem={onRemoveBundleItem}
                        bundleItemAccordionIndex={bundleItemAccordionIndex}
                        isBundleCategoryLimitReached={isBundleCategoryLimitReached}
                        addBundleItemToBundleProduct={addBundleItemToBundleProduct}
                        isOnlineExpress={isOnlineExpress}
                        bundleItemIndex={bundleItemIndex}
                        shouldShowQuantityIndicator={shouldShowQuantityIndicator}
                        onSelectModification={onSelectModification}
                        setBundleCartProduct={setBundleCartProduct}
                    />
                );
            })}
        </Box>
    );
};
