import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import { SEORestaurant, Shop } from "Types";
import { useLanguage } from "Providers";
import { useQoplaStore } from "Stores";
import { createOnlineOrderSEO } from "Utils";
import { useEffectOnce } from "Hooks";

type Props = {
    restaurant: Shop;
};

const OnlineHelmet: React.FC<Props> = ({ restaurant }) => {
    const [SEORestaurantContext, setSEORestaurantContext] = useState<SEORestaurant | null>(null);
    const { translate } = useLanguage();
    const { companyLocale } = useQoplaStore();
    const location = useLocation();

    const url = `${window.location.origin}${location.pathname}`;

    useEffectOnce(() => {
        try {
            const createdSEORestaurantContext = createOnlineOrderSEO(restaurant, url, companyLocale);
            setSEORestaurantContext(createdSEORestaurantContext);
        } catch (err: any) {
            Sentry.withScope(scope => {
                scope.setExtra("FROM_URL", url);
                Sentry.captureMessage(`[SEO_ENTRY] [ONLINE_ORDER] ${err.message}`);
            });
        }
    });

    return (
        <>
            {/*@ts-ignore*/}
            <Helmet prioritizeSeoTags>
                <title>
                    {restaurant.name} {translate("order")} | {restaurant.contactInformation.city} | Qopla
                </title>

                {location.pathname.includes("/express/") && (
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
                    />
                )}
                <meta
                    name="description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta
                    name="twitter:title"
                    content={`${restaurant.name} Beställ | ${restaurant.contactInformation.city} | qopla`}
                />
                <meta
                    name="twitter:description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta
                    property="og:title"
                    content={`${restaurant.name} Beställ | ${restaurant.contactInformation.city} | qopla`}
                />
                <meta
                    property="og:description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta property="og:image" content={restaurant.imageUrl} />
                <meta
                    name="robots"
                    content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
                />
                <link rel="canonical" href={url} />
                {!!SEORestaurantContext && (
                    <script type="application/ld+json">{JSON.stringify(SEORestaurantContext)}</script>
                )}
            </Helmet>
        </>
    );
};

export default OnlineHelmet;
