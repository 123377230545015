import React from "react";

import { Flex, Header, Label } from "Atoms";
import { useOnline } from "Providers";
import { AddonGroupsLevel } from "../";

type Props = {
    showHeader: boolean;
    level: AddonGroupsLevel;
    companySetting: boolean;
    addonGroupName: string;
    limit: number;
};

export const OnlineAddonsGroupHeader: React.FC<Props> = ({ showHeader, level, addonGroupName, limit }) => {
    const { isOnlineExpress } = useOnline();
    if (!showHeader) return null;

    if (level === AddonGroupsLevel.Product) {
        return (
            <Flex backgroundColor="gray.300" rounded="md" py={2} px={4} align="baseline" justify="space-between">
                <Header as="h4" fontWeight="normal" fontSize={isOnlineExpress ? "2xl" : "xl"} mb={2}>
                    {addonGroupName}
                </Header>
                {limit != 0 && (
                    <Label color="black" paddingRight={4}>
                        Max {limit} st.
                    </Label>
                )}
            </Flex>
        );
    } else {
        return (
            <Flex direction="column" backgroundColor="gray.300" rounded="md" py={2} px={4}>
                <Header as="h5" size={isOnlineExpress ? "md" : "sm"} m="0">
                    {addonGroupName}
                </Header>
            </Flex>
        );
    }
};
