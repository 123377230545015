import React from "react";

import { SelectedBundleProductItem, Modification, Addon, CartProduct } from "Types";
import { Box, Button, Flex, Header } from "Atoms";
import { OnlineItemQuantityIndicator } from "OnlineComponents";
import { AddonOperation, BundleItemOperation, Languagekey, useLanguage } from "Providers";
import { AvailableBundleItem } from ".";
import { getSingleModification } from "../utils";
import { getModifications } from "TempUtils";
import { OnlineText } from "../../OnlineText";
import { AddonGroupsLevel, OnlineAddonsGroups } from "../../OnlineAddonsGroups";
import { useOrderWindowsStore } from "Stores";

type Props = {
    bundleCartProduct: CartProduct;
    bundleItem: AvailableBundleItem;
    addBundleItemToBundleProduct: Function;
    isBundleCategoryLimitReached: boolean;
    onAddNewBundleItem: any;
    bundleItemAccordionIndex: number;
    isOnlineExpress: boolean;
    bundleItemIndex: number;
    selectedBundleItem?: SelectedBundleProductItem;
    onSelectModification: (modificationKey: string, modificationToAdd: Modification, bundleItemIndex: number) => void;
    onRemoveBundleItem: (
        bundleItem: AvailableBundleItem,
        bundleItemAccordionIndex: number,
        closeAccordionItem: boolean
    ) => void;

    bundleItemAddons: Addon[];
    bundleItemInnerIndex: number;
    shouldShowQuantityIndicator: boolean;
    setBundleCartProduct: (cartProduct: CartProduct | null) => void;
};

export const BundleItemContent: React.FC<Props> = ({
    bundleCartProduct,
    bundleItem,
    isBundleCategoryLimitReached,
    onAddNewBundleItem,
    bundleItemAccordionIndex,
    isOnlineExpress,
    bundleItemIndex,
    onRemoveBundleItem,
    onSelectModification,
    bundleItemAddons,
    selectedBundleItem,
    bundleItemInnerIndex,
    shouldShowQuantityIndicator,
    setBundleCartProduct
}) => {
    const { CartManager } = useOrderWindowsStore();
    const { updateBundleItemWithAddon } = CartManager;
    const { translate } = useLanguage();
    const modsToUse = bundleItem.modificationsToUse ? getModifications(bundleItem.modificationsToUse) : [];
    const singleMods = bundleItem.modificationsToUse ? getSingleModification(bundleItem.modificationsToUse) : null;

    const bundleItemText = `${bundleItem.name} ${bundleItemInnerIndex + 1}`;
    const bundleItemAddonsText = `${translate("addonsFor")} ${bundleItemText}`;

    const isFirstBundleItemInCategory = bundleItemInnerIndex === 0;
    const hasNoAddonsNorModifications = !(bundleItemAddons || bundleItem.modificationsToUse);
    const shouldHide = !isFirstBundleItemInCategory && hasNoAddonsNorModifications;

    if (shouldHide) {
        return null;
    }

    const text = bundleItemAddons
        ? bundleItemAddonsText
        : bundleItem.modificationsToUse && !singleMods
        ? bundleItemText
        : "";
    const hasText = !!text;

    const _shouldShowQuantityIndicator = isFirstBundleItemInCategory
        ? !shouldShowQuantityIndicator
        : bundleItemAddons || (modsToUse && !singleMods);

    return (
        <Box>
            <Flex justify={hasText ? "space-between" : "flex-end"} align="center">
                <Header as="h5" size={isOnlineExpress ? "md" : "sm"} m="0">
                    {text}
                </Header>
                {_shouldShowQuantityIndicator && (
                    <OnlineItemQuantityIndicator
                        add={{
                            function: () => onAddNewBundleItem(bundleItem, BundleItemOperation.ADD, null),
                            isDisabled: isBundleCategoryLimitReached || bundleItem.outOfStock
                        }}
                        decrement={{
                            function: () => {
                                onRemoveBundleItem(bundleItem, bundleItemAccordionIndex, !bundleItem.quantity);
                            },
                            color: !isFirstBundleItemInCategory ? "red" : "gray",
                            isDisabled: false
                        }}
                        quantity={bundleItem.quantity}
                        onlyShowDecrement={!isFirstBundleItemInCategory}
                    />
                )}
            </Flex>
            <Box>
                {!singleMods
                    ? modsToUse.map((mod, modIndex) => {
                          if (!mod.mods.length) return null;

                          return (
                              <Flex key={modIndex} direction="column" mb={6}>
                                  <OnlineText>{translate(mod.name as Languagekey)}</OnlineText>
                                  <Box>
                                      {mod.mods.map((modification: any, oIndex: number) => {
                                          const isSelected =
                                              //@ts-ignore
                                              selectedBundleItem?.modifications[mod.name]?.name === modification.name;

                                          return (
                                              <Button
                                                  size={isOnlineExpress ? "lg" : "sm"}
                                                  themeColor={isSelected ? "green" : "gray"}
                                                  key={oIndex}
                                                  mr={2}
                                                  mb={2}
                                                  onClick={() =>
                                                      onSelectModification(mod.name, modification, bundleItemIndex)
                                                  }
                                              >
                                                  <OnlineText as="span">{modification.name}</OnlineText>
                                                  {!!modification.addonPrice && (
                                                      <OnlineText as="span" ml={2}>
                                                          +{modification.addonPrice} kr
                                                      </OnlineText>
                                                  )}
                                              </Button>
                                          );
                                      })}
                                  </Box>
                              </Flex>
                          );
                      })
                    : null}
            </Box>
            {bundleItemAddons && (
                <Box>
                    <OnlineAddonsGroups
                        level={AddonGroupsLevel.BundleItem}
                        productId={bundleItem.id}
                        productName={bundleItem.name}
                        selectedAddons={selectedBundleItem ? selectedBundleItem.addons : []}
                        onAddAddon={addedAddon => {
                            setBundleCartProduct(
                                updateBundleItemWithAddon(
                                    bundleCartProduct,
                                    addedAddon,
                                    bundleItemIndex,
                                    AddonOperation.ADD
                                )
                            );
                        }}
                        onDecrementAddon={removeAddon => {
                            setBundleCartProduct(
                                updateBundleItemWithAddon(
                                    bundleCartProduct,
                                    removeAddon,
                                    bundleItemIndex,
                                    AddonOperation.DECREMENT
                                )
                            );
                        }}
                        onResetAddons={resetAddon => {
                            setBundleCartProduct(
                                updateBundleItemWithAddon(
                                    bundleCartProduct,
                                    resetAddon,
                                    bundleItemIndex,
                                    AddonOperation.RESET
                                )
                            );
                        }}
                        onIncrementAddon={resetAddon => {
                            setBundleCartProduct(
                                updateBundleItemWithAddon(
                                    bundleCartProduct,
                                    resetAddon,
                                    bundleItemIndex,
                                    AddonOperation.INCREMENT
                                )
                            );
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};
