import React from "react";

import { ActiveHour } from "Types";
import { Text } from "Atoms";
import { useLanguage, useOnline } from "Providers";

type Props = {
    activeHours: ActiveHour[];
    color?: string;
};

export const OnlineOpeningHour: React.FC<Props> = ({ color }) => {
    const { translate } = useLanguage();
    const { getTodaysActiveHour } = useOnline();
    const activeHour = getTodaysActiveHour();

    const openingHourText = !activeHour
        ? translate("closed")
        : `${activeHour.startingHour} - ${activeHour.stoppingHour}`;

    return (
        <Text mr={1} color={color}>
            {translate("today")}: {openingHourText}
        </Text>
    );
};
