import React from "react";

import { AccordionTitle } from "Organisms";
import { Addon } from "Types";
import { Box, NewButton, Checkbox, Stack } from "Atoms";
import { useLanguage } from "Providers";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { OnlineText } from "OnlineComponents";
import { mathSign } from "../../../../../../admin/components/poses/pos/utils";
import { LanguageText } from "Components";

type Props = {
    outOfStock: boolean;
    isOnlineExpress: boolean;
    ingredient: Addon;
    isSelected: boolean;
    onClick: () => void;
};

export const OnlineAddonsGroupAccordionTitle: React.FC<Props> = ({
    outOfStock,
    isOnlineExpress,
    ingredient,
    isSelected,
    onClick
}) => {
    const { translate } = useLanguage();

    if (isOnlineExpress) {
        let styles = {
            themeColor: "gray",
            variant: "outline"
        };

        if (isSelected) {
            styles = {
                themeColor: "green",
                variant: "solid"
            };
        }

        return (
            <AccordionTitle justifyContent="flex-end" as="div">
                <Box flex="1" textAlign="left">
                    <OnlineText as={outOfStock ? "del" : "span"}>{ingredient.name}</OnlineText>
                    {outOfStock && <LanguageText tid="outOfStock" as="span" fontSize="md" ml={1} />}
                </Box>
                {!outOfStock && (
                    <OnlineText as="span">
                        {mathSign(ingredient.price)} {formatSwedishFinancialNumbers(ingredient.price)}
                    </OnlineText>
                )}
                {!outOfStock && (
                    <NewButton size="xl" ml={4} width={125} onClick={onClick} {...(styles as any)}>
                        {isSelected ? translate("added") : translate("addTo")}
                    </NewButton>
                )}
            </AccordionTitle>
        );
    } else {
        return (
            <Stack isInline stretch={2} px={4} align="center">
                <Checkbox size="lg" value="" isDisabled={outOfStock} isChecked={isSelected} onChange={onClick} />
                <AccordionTitle justifyContent="flex-end" onClick={onClick}>
                    <Box flex="1" textAlign="left">
                        <OnlineText as={outOfStock ? "del" : "span"}>{ingredient.name}</OnlineText>
                        {outOfStock && <LanguageText tid="outOfStock" as="span" fontSize="md" ml={1} />}
                    </Box>
                    <OnlineText as="span">
                        {mathSign(ingredient.price)} {formatSwedishFinancialNumbers(ingredient.price)}
                    </OnlineText>
                </AccordionTitle>
            </Stack>
        );
    }
};
