import React, { useMemo } from "react";

import { Addon, ProductIngredientGroups } from "Types";
import { usePos, useLanguage } from "Providers";
import { OnlineAddonsGroup } from "./components";
import { grouped } from "./utils/onlineOrderAddonsListUtils";

type Props = {
    productId: string;
    productName: string;
    selectedAddons: Addon[];
    showHeader?: boolean;
    onAddAddon: (addon: Addon) => void;
    onIncrementAddon: (addon: Addon) => void;
    onDecrementAddon: (addon: Addon) => void;
    onResetAddons: (addon: Addon) => void;
    maxAddonsToShow?: number;
    level: AddonGroupsLevel;
};

export enum AddonGroupsLevel {
    Product = "product",
    BundleItem = "bundleItem"
}

export const OnlineAddonsGroups = ({
    productId,
    showHeader = false,
    maxAddonsToShow = 5,
    productName,
    ...rest
}: Props) => {
    const { addons } = usePos();
    const { translate } = useLanguage();

    const productAddonGroups: ProductIngredientGroups = addons[productId];

    if (!productAddonGroups) return null;

    const extraAddonsForText = translate("extraAddonsFor");

    const copy = productAddonGroups.slice();
    const addonsGroups = useMemo(() => grouped(copy, productName, extraAddonsForText), []);

    return (
        <>
            {addonsGroups.map((productAddonGroup, i) => (
                <OnlineAddonsGroup
                    key={i}
                    productAddonGroup={productAddonGroup}
                    showHeader={showHeader}
                    productName={productName}
                    {...rest}
                />
            ))}
        </>
    );
};
